h1{
    padding-top: 3%;
    color: rgba(255, 129, 39, 0.82);
    padding-bottom: 2%;
}

h2{
    color: rgba(255, 129, 39, 0.82);
}

h3{
    color: rgba(255, 129, 39, 0.82);
}

h6{
    color: rgba(255, 255, 255, 0.754);
}

.right{
    text-align: right;
}
