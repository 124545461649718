.cardImg{
    width:15%;
    height:15%;
    float: center;
    color: white;
    margin-right: auto;
    margin-left: auto;
}
.title{
    color:white;
    text-align: center;
}