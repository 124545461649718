.allign_elements_center{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.img-size{
  width: 100%;
}

.right{
  text-align: right;
}

.black{
  background-color: black;
}

.opacity_width{
  opacity: 80%;
  width: 100%;
}

.container video{
  height: 100%;
  width: 100vw;
}

.scrollContainer{
  overflow: scroll;
  height: 100vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  overflow-x: clip;
}

.bg{
  scroll-snap-align: start;
  scroll-snap-stop: always;
  /* Full height */
  width: 100%;
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.first{
    /* The image used */
    background-image: url('/Users/robertjelic/Documents/ToniWebSite/public/Slike/Artboard1.png');
}
.second{
   /* The image used */
   background-image: url('/Users/robertjelic/Documents/ToniWebSite/public/Slike/Artboard2.png');
}
.third{
  /* The image used */
  background-image: url('/Users/robertjelic/Documents/ToniWebSite/public/Slike/Artboard3.png');
}

.fourth{
   /* The image used */
   background-image: url('/Users/robertjelic/Documents/ToniWebSite/public/Slike/Asset99.png');
}

.fifth{
  /* The image used */
  background-image: url('/Users/robertjelic/Documents/ToniWebSite/public/Slike/Asset100.png');
}

.Education{
  height: 100%;
  width: auto;
}

.scroll{
  overflow: scroll;
}

.small-text{
  font-size: small;
  color: rgba(255, 255, 255, 0.754);
}

.video-wrapper {
  position: relative;
  width: 97%;
  height: 97vh; /* Full height of the viewport */
  overflow: hidden;
  border-style: inset;
  border-radius: 8px; /* Optional: Add rounded corners */
  border-color: rgba(255, 129, 39, 0.82);
}

.video-wrapper video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the video */
  min-width: 100%; /* Ensures the video covers the width */
  min-height: 100%; /* Ensures the video covers the height */
  object-fit: cover;/* Ensures the video covers the container without distortion */
}

.who-am-i-txt{
  text-align: center;
  color: #cccccceb; /* Muted gold for headings */
  /* font-family: 'American Typewriter'; */
  font-size: clamp(1rem, 2.5vw, 1.5rem); /* Scales between 1rem and 1.5rem */
  line-height: 1.6; 
}